<template>
  <div
    class="regbatch-result-review-container"
    :class="{ 'is-loading': loading }"
  >
    <CRow v-if="batch">
      <CCol>
        <CCard class="no-border">
          <CCardBody>
            <CRow v-if="batch" class="regbatch-result-callout-row">
              <CCol col="6" md="3">
                <CCallout color="secondary">
                  <small
                    id="regbatch-result-filename-callout-label"
                    class="text-muted"
                    >Original Filename</small
                  ><br />
                  <strong
                    id="regbatch-result-filename-callout-value"
                    class="h4"
                    aria-labelledby="regbatch-result-filename-callout-label"
                    >{{ batch.filename }}</strong
                  >
                </CCallout>
              </CCol>
              <CCol col="6" md="3">
                <CCallout color="secondary">
                  <small
                    id="regbatch-result-description-callout-label"
                    class="text-muted"
                    >Description</small
                  ><br />
                  <strong
                    id="regbatch-result-description-callout-value"
                    class="h4"
                    aria-labelledby="regbatch-result-description-callout-label"
                    >{{ batch.description || '--' }}</strong
                  >
                </CCallout>
              </CCol>
              <CCol col="6" md="3">
                <CCallout color="secondary">
                  <small
                    id="regbatch-result-count-callout-label"
                    class="text-muted"
                    >Total Registrations</small
                  ><br />
                  <strong
                    id="regbatch-result-count-callout-value"
                    class="h4"
                    aria-labelledby="regbatch-result-count-callout-label"
                    >{{ $format.number(batch.itemCount) }}</strong
                  >
                </CCallout>
              </CCol>
              <CCol col="6" md="3">
                <CCallout color="secondary">
                  <small
                    id="regbatch-result-findings-callout-label"
                    class="text-muted"
                    >Findings and Errors</small
                  ><br />
                  <strong
                    id="regbatch-result-count-findings-value"
                    class="h4"
                    aria-labelledby="regbatch-result-count-callout-label"
                    >{{
                      $format.number(
                        (batch.findingsCount || 0) + (batch.errorsCount || 0),
                      ) || '--'
                    }}</strong
                  >
                </CCallout>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader v-if="batch">
            <div style="display: flex">
              <span class="h2"
                >Batch Verification Results: {{ batch.name }}</span
              >
              <span
                class="text-muted"
                style="
                  margin-left: auto;
                  margin-right: 5px;
                  margin-top: auto;
                  margin-bottom: auto;
                "
              >
                Executed:
                {{
                  $format.timestamp(
                    batch.completedAt || batch.startedAt || batch.createdAt,
                  )
                }}
              </span>
              <CButton
                id="btn-batch-result-refresh"
                class="btn-crd btn-add"
                variant="ghost"
                color="info"
                :title="
                  autoRefreshing
                    ? 'Auto-Refreshing'
                    : loading
                    ? 'Refreshing'
                    : 'Refresh'
                "
                :disabled="loading || autoRefreshing"
                @click="fetchDetails(batchId, queryArgs)"
              >
                <CIcon
                  name="cil-reload"
                  :class="{ spinning: autoRefreshing || loading }"
                />
              </CButton>
              <MDownloadButton
                :id="`btn-download-registration-batch_${batchId}`"
                :href="`/api/registrations/results/${batchId}/download`"
                variant="ghost"
                size="std"
                style="margin-left: 5px; margin-right: 5px"
              />
              <MDeleteButton
                :id="`btn-delete-registration-batch_${batchId}`"
                class="mx-1"
                variant="ghost"
                title="Delete Results"
                style="margin-left: 5px; margin-right: 5px"
                @click="handleDelete()"
              />
            </div>
          </CCardHeader>
          <CCardBody>
            <MDataTable
              id="tbl-registration-batch-details"
              :fields="fields"
              fixed
              striped
              :loading="loading"
              :items="content"
              :column-filter="true"
              :sorter="{ external: true }"
              :sorter-value.sync="columnSorter"
              :table-filter-value.sync="tableFilterValue"
              :pagination="false"
              :per-page="pageSize"
              :table-filter="false"
            >
              <template #table-controls>
                <MTablePaginator
                  :page.sync="pageNumber"
                  :per-page.sync="pageSize"
                  :total-rows="totalCount"
                  :page-count="totalPages"
                  :filtered-rows="content.length"
                  :filtering="false"
                >
                </MTablePaginator>
              </template>
              <template #statusLabel="{ item }">
                <CIcon
                  v-if="item.statusIconName"
                  :name="item.statusIconName"
                  class="mr-2"
                  :class="item.statusIconClass"
                /><CBadge>{{ item.statusLabel }}</CBadge>
              </template>
              <template #taxpayerName-filter>
                <CInput type="text" @change="(v) => (taxpayerNameFilter = v)" />
              </template>
              <template #country-filter>
                <CInput type="text" @change="(v) => (countryFilter = v)" />
              </template>
              <template #mainDivision-filter>
                <CInput type="text" @change="(v) => (mainDivisionFilter = v)" />
              </template>
              <template #registrationNumber-filter>
                <CInput
                  type="text"
                  @change="(v) => (registrationNumberFilter = v)"
                />
              </template>
              <template #statusLabel-filter>
                <MSelect
                  :options="[
                    { value: 'ANY_INVALID', label: 'Any Invalid' },
                    { value: 'PENDING', label: 'Pending' },
                    { value: 'INVALID_INPUT', label: 'Invalid Input' },
                    { value: 'IN_PROGRESS', label: 'In Progress' },
                    { value: 'VALID', label: 'Valid' },
                    { value: 'INVALID_FORMAT', label: 'Invalid Format' },
                    { value: 'NOT_FOUND', label: 'Not Found' },
                    { value: 'NAME_MISMATCH', label: 'Name Mismatch' },
                    { value: 'UNSUPPORTED', label: 'Unsupported' },
                  ]"
                  :required="false"
                  taggable
                  key-only
                  clearable
                  filterable
                  :value.sync="validationStatusFilter"
                />
              </template>
              <template #taxpayerType-filter>
                <MSelect
                  :value.sync="taxpayerTypeFilter"
                  :options="[
                    { value: 'CUSTOMER', label: 'Customer' },
                    { value: 'VENDOR', label: 'Vendor' },
                    { value: 'UNKNOWN', label: 'Unknown' },
                    { value: 'TAXPAYER', label: 'Taxpayer' },
                    { value: 'OTHER', label: 'Other' },
                  ]"
                  :required="false"
                  taggable
                  key-only
                  clearable
                  filterable
                />
              </template>
              <template #details="{ item }">
                <div
                  class="mt-2"
                  style="width: 90%; margin-left: auto; margin-right: auto"
                >
                  <CRow>
                    <CCol
                      v-if="item.messages && item.messages.length > 0"
                      col="6"
                      lg="4"
                    >
                      <div class="h6">Messages</div>
                      <hr />
                      <ul>
                        <li
                          v-for="(message, idx) in item.messages"
                          :key="`${item.id}_${idx}`"
                        >
                          {{ message }}
                        </li>
                      </ul>
                    </CCol>
                    <CCol v-else col="6" lg="4"> </CCol>
                    <CCol v-if="item.ev.checked" col="6" lg="4">
                      <div class="h6">Extended Verification</div>
                      <hr />
                      <dl class="reg-sample-list">
                        <dt class="dt-header">System</dt>
                        <dd class="dd-header">Name Checked</dd>
                        <dt>
                          {{ item.ev.systemName || '--' }}
                        </dt>
                        <dd>
                          {{ item.ev.nameChecked ? 'Yes' : 'No' }}
                        </dd>
                      </dl>
                    </CCol>
                    <CCol v-if="!!item.matchingFormat" col="6" lg="4">
                      <div class="h6">Verified Format</div>
                      <hr />
                      <dl class="reg-sample-list">
                        <dt class="dt-header">Sample</dt>
                        <dd class="dd-header">Description</dd>
                        <dt>
                          {{ item.matchingFormat.sample }}
                        </dt>
                        <dd>
                          {{ item.matchingFormat.description }}
                        </dd>
                      </dl>
                    </CCol>
                    <CCol v-if="item.formats.length > 0" col="6" lg="4">
                      <div class="h6">Valid Formats</div>
                      <hr />
                      <dl class="reg-sample-list">
                        <dt class="dt-header">Sample</dt>
                        <dd class="dd-header">Description</dd>
                        <template v-for="(regMask, index) in item.formats">
                          <dt :key="`dd-sample-format-${item.id}-${index}`">
                            {{ regMask.sample }}
                          </dt>
                          <dd
                            :key="`dd-sample-format-desc-${item.id}-${index}`"
                          >
                            {{ regMask.description }}
                          </dd>
                        </template>
                      </dl>
                    </CCol>
                  </CRow>
                </div>
              </template>
            </MDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import MDownloadButton from '@/components/Buttons/MDownloadButton';
import MDataTable from '@/components/MDataTable/MDataTable';
import MTablePaginator from '@/components/MDataTable/MTablePaginator';
import api from '@/api';
const TABLE_FIELDS = [
  {
    key: 'statusLabel',
    label: 'Status',
    sorter: true,
  },
  {
    key: 'taxpayerName',
    label: 'Entity Name',
    sorter: true,
  },
  {
    key: 'country',
    label: 'Country',
    sorter: true,
  },
  {
    key: 'mainDivision',
    label: 'Main Division',
    sorter: true,
  },
  {
    key: 'registrationNumber',
    label: 'Registration',
    sorter: true,
  },
  {
    key: 'taxpayerType',
    label: 'Type',
    sorter: true,
  },
  {
    key: 'sourceSystemName',
    label: 'Source',
    sorter: true,
    hidden: true,
  },
  {
    key: 'sourceTaxpayerId',
    label: 'Source ID',
    sorter: true,
    hidden: true,
  },
];

export default {
  name: 'RegistrationBatchDetails',
  components: {
    MDataTable,
    MTablePaginator,
    MDownloadButton,
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      autoRefreshing: false,
      loading: false,
      batch: null,
      content: [],
      hasSourceSystemName: false,
      hasSourceTaxpayerId: false,
      page: 1,
      size: 50,
      totalCount: 0,
      totalPages: 1,
      internalTableFilterValue: null,
      internalColumnFilterValue: null,
      internalSorter: {
        column: 'taxpayerName',
        asc: true,
      },
      filters: {
        taxpayerType: null,
        validationStatus: null,
        country: null,
        mainDivision: null,
        taxpayerName: null,
        registrationNumber: null,
      },
    };
  },
  computed: {
    fields() {
      let fields = TABLE_FIELDS;
      fields[6].hidden = !this.hasSourceSystemName;
      fields[7].hidden = !this.hasSourceTaxpayerId;

      return fields;
    },
    taxpayerTypeFilter: {
      set(v) {
        this.filters.taxpayerType = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
        };
        if (v) {
          qa.taxpayerType = v;
        }
        this.fetchDetails(this.batchId, qa);
      },
      get() {
        return this.filters.taxpayerType;
      },
    },
    taxpayerNameFilter: {
      set(v) {
        this.filters.taxpayerName = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
        };
        if (v) {
          qa.taxpayerName = v;
        }
        this.fetchDetails(this.batchId, qa);
      },
      get() {
        return this.filters.taxpayerName;
      },
    },
    countryFilter: {
      set(v) {
        this.filters.country = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
        };
        if (v) {
          qa.country = v;
        }
        this.fetchDetails(this.batchId, qa);
      },
      get() {
        return this.filters.country;
      },
    },
    mainDivisionFilter: {
      set(v) {
        this.filters.mainDivision = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
        };
        if (v) {
          qa.mainDivision = v;
        }
        this.fetchDetails(this.batchId, qa);
      },
      get() {
        return this.filters.mainDivision;
      },
    },
    registrationNumberFilter: {
      set(v) {
        this.filters.registrationNumber = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
        };
        if (v) {
          qa.registrationNumber = v;
        }
        this.fetchDetails(this.batchId, qa);
      },
      get() {
        return this.filters.registrationNumber;
      },
    },
    validationStatusFilter: {
      set(v) {
        this.filters.validationStatus = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
        };
        if (v) {
          qa.validationStatus = v;
        }
        this.fetchDetails(this.batchId, qa);
      },
      get() {
        return this.filters.validationStatus;
      },
    },
    pageNumber: {
      get() {
        return this.page;
      },
      set(v) {
        if (this.loading) {
          return;
        }

        let pageNumber = v;
        if (!v || v < 1) {
          pageNumber = 1;
        }

        if (pageNumber !== this.page) {
          this.fetchDetails(this.batchId, {
            ...this.queryArgs,
            page: pageNumber - 1,
          });
        }

        this.page = v;
      },
    },
    pageSize: {
      get() {
        return this.size;
      },
      set(v) {
        let pageSize = v;
        if (!v || v < 5) {
          pageSize = 5;
        }

        if (this.size !== pageSize) {
          this.fetchDetails(this.batchId, {
            ...this.queryArgs,
            size: pageSize,
            page: 0,
          });
        }
        this.size = v;
      },
    },
    queryArgs() {
      let args = {
        page: this.pageNumber - 1,
        size: this.pageSize,
        queryText: this.tableFilterValue?.trim(),
        sort: this.calculatedSort,
      };

      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key]) {
          args[key] = this.filters[key];
        }
      });

      return args;
    },
    tableFilterValue: {
      get() {
        return this.internalTableFilterValue;
      },
      set(v) {
        this.internalTableFilterValue = v;
        let qa = {
          ...this.queryArgs,
          page: 0,
          queryText: v?.trim(),
        };
        this.fetchDetails(this.batchId, qa);
      },
    },
    calculatedSort() {
      return this.prepareSortString(this.columnSorter);
    },
    columnSorter: {
      get() {
        return this.internalSorter;
      },
      set(v) {
        let sort;
        if (!v) {
          sort = {
            external: true,
            resetable: false,
            column: 'taxpayerName',
            asc: true,
          };
        } else {
          let column = v.column || 'taxpayerName';
          let asc = v.asc === false ? false : true;

          sort = {
            external: true,
            resetable: false,
            column,
            asc,
          };
        }

        this.internalSorter = sort;

        const qa = {
          ...this.queryArgs,
          page: 0,
          sort: this.prepareSortString(sort),
        };

        this.fetchDetails(this.batchId, qa);
      },
    },
  },
  watch: {
    batchId(value) {
      if (value) {
        this.fetchDetails(value);
      }
    },
  },
  mounted() {
    if (this.batchId) {
      this.fetchDetails(this.batchId);
    }
  },
  methods: {
    getBadgeIcon(o) {
      if (o.isInvalid) {
        if (o.status === 'INVALID_INPUT') {
          return 'cis-exclamation-circle';
        }
        return 'cis-warning';
      } else if (o.isValid) {
        return 'cis-check-alt';
      } else {
        if (o.status === 'UNSUPPORTED') {
          return 'cis-x';
        }
        return 'cis-arrow-circle-right';
      }
    },
    getBadgeIconClass(o) {
      if (o.isInvalid) {
        if (o.status === 'INVALID_INPUT') {
          return 'text-danger';
        }
        return 'text-warning';
      } else if (o.isValid) {
        return 'text-success';
      } else {
        if (o.status === 'UNSUPPORTED') {
          return 'text-secondary';
        }
        return 'text-info';
      }
    },
    prepareSortString(sort) {
      let columnName = this.getSortingColumnName(sort.column);
      const dir = sort.asc ? 'ASC' : 'DESC';
      switch (sort.column) {
        case 'country':
          return `parentGeoKey ${dir},parentGeoType,geoKey ${dir},geoType`;
        case 'mainDivision':
          return `parentGeoKey ASC,geoKey ${dir}`;
        default:
          return `${columnName} ${dir}`;
      }
    },
    getSortingColumnName(columnName) {
      switch (columnName) {
        case 'statusLabel':
          return 'validationStatus';
        case 'country':
          return 'parentGeoKey,geoKey';
        case 'mainDivision':
          return 'geoKey,parentGeoKey';
        default:
          return columnName;
      }
    },
    fetchDetails(batchId, queryArgs) {
      const self = this;
      this.loading = true;

      api.registrations
        .getBatchResults(batchId || this.batchId, queryArgs)
        .then((data) => {
          var hasSourceSystemName = false;
          var hasSourceTaxpayerId = false;
          let setAutoRefresh = false;
          self.content = (data.content || []).map((o) => {
            if (o.sourceSystemName && o.sourceSystemName !== '') {
              hasSourceSystemName = true;
            }

            if (o.sourceTaxpayerId && o.sourceTaxpayerId !== '') {
              hasSourceTaxpayerId = true;
            }

            // if (o.status === 'PENDING' || o.status === 'IN_PROGRESS') {
            //   setAutoRefresh = true;
            // }
            return Object.freeze({
              ...o,
              taxpayerName: o.taxpayerName || '--',
              registrationNumber: o.registrationNumber || '--',
              sourceSystemName: o.sourceSystemName || '--',
              sourceTaxpayerId: o.sourceTaxpayerId || '--',
              taxpayerType: self.$t(`regvalTaxpayerType.${o.taxpayerType}`),
              statusLabel: self.$t(`regvalStatus.${o.status}`),
              statusIconName: self.getBadgeIcon(o),
              statusIconClass: self.getBadgeIconClass(o),
              showDetails: o.hasExtendedData,
            });
          });
          self.batch = Object.freeze(data.batch || {});
          if (setAutoRefresh) {
            setTimeout(() => {
              self.fetchDetails(batchId, queryArgs);
            }, 10000);
          }
          self.$nextTick(() => {
            self.hasSourceSystemName = hasSourceSystemName;
            self.hasSourceTaxpayerId = hasSourceTaxpayerId;
            self.totalCount = data.totalElements;
            self.page = (data.pageable?.pageNumber || 0) + 1;
            self.totalPages = data.totalPages;
            self.autoRefreshing = setAutoRefresh;
          });
        })
        .finally(() => {
          self.$nextTick(() => {
            self.loading = false;
          });
        });
    },
    handleDelete() {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Delete',
          text: 'Are you sure you wish to delete this validation batch?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            api.registrations
              .deleteResults(self.batchId)
              .then(() => {
                self.$router.push({ name: 'RapidVerifyDashboard' });
              })
              .catch((err) => {
                self.$log.error(err);
                self.$swal.fire({
                  title: 'Error',
                  text: 'The batch could not be deleted.',
                  icon: 'error',
                });
              });
          }
        });
    },
  },
};
</script>
<style lang="scss">
#tbl-registration-batch-details {
  .table-sticky {
    height: 600px;
  }
  th {
    .v-select {
      min-width: 200px;
    }
  }
}
</style>
